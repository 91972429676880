<!--
 * @Description: 商品 SPU 转 SKU
 * @Date: 2024-03-22 10:37:18
 * @LastEditTime: 2024-03-25 17:30:01
-->
<template>
  <div class="commodity-spu-to-sku">
    <view-header :isHome="false" ref="navheader" />
    <!-- <div class="gap"></div> -->

    <!-- 模块 1 -->
    <section class="banner-wrap">
      <div class="show-content"></div>

      <div class="banner-header_cont">
        <div class="banner-title">SPU转SKU</div>
        <div class="banner-text">
          <div class="banner-item">
            将spu商品结构拆为sku结构，并为sku生成新的标题、首图、属性、描述等信息
          </div>
        </div>
      </div>
    </section>

    <!-- <banner-head
      :title="'SPU转SKU'"
      :desc="'将spu商品结构拆为sku结构，并为sku生成新的标题、首图、属性、描述等信息'"
      :bannerImg="bannerBg"
    > -->
    <!-- <div class="header-custom" slot="content">
        <img
          class="banner1"
          src="@/assets/img/commodity-spu-to-sku/banner-1.png"
        />
        <img
          class="banner2"
          src="@/assets/img/commodity-spu-to-sku/banner-2.png"
        />
        <img
          class="banner3"
          src="@/assets/img/commodity-spu-to-sku/banner-3.png"
        />
      </div> -->
    <!-- </banner-head> -->

    <!-- 核心功能 -->
    <div class="core-function">
      <common-title title="核心功能" />

      <div class="core-main">
        <div class="text-container">
          <div class="text-item">
            <h6 class="subtitle">sku信息生成</h6>
            <p class="text">
              基于对原有spu商品结构中各个规格信息的图文理解，将其分拆为多个sku商品结构（结合原有信息生成sku标题、属性、描述、首图等）
            </p>
          </div>

          <div class="text-item">
            <h6 class="subtitle">优化商品图文信息相关性</h6>
            <p class="text">
              分拆出的sku，标题中更通顺、包含更精准长尾词，与新首图的相关性更高
            </p>
          </div>
        </div>

        <div class="image-container">
          <img
            src="@/assets/img/commodity-spu-to-sku/core-function-image.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <div class="application-scene-wrap">
        <CommonTitle title="应用场景" />

        <div class="application-scene-main">
          <div class="scene_1">
            <div class="image-content">
              <img
                src="@/assets/img/commodity-spu-to-sku/app-scene-1-image.png"
                alt=""
              />
            </div>

            <div class="intro-content">
              <div class="text-container">
                <h6 class="subtitle">多渠道经营商品结构适配</h6>
                <p class="text">适配不同渠道经营中对商品结构的差异化要求</p>
              </div>
            </div>
          </div>

          <div class="scene_2">
            <div class="intro-content">
              <div class="text-container">
                <h6 class="subtitle">搜索类广告投放提效</h6>
                <p class="text">
                  对于搜索类广告平台，投放素材表达颗粒度更细、快速适配/提供平台对特有属性及信息的要求，提升投放效率及效果
                </p>
              </div>
            </div>

            <div class="image-content">
              <img
                src="@/assets/img/commodity-spu-to-sku/app-scene-2-image.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <FooterVNode />
  </div>
</template>

<script>
import ViewHeader from '../../components/viewheader/viewheader.vue';
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumnTwo from './components/ColumuTwo.vue';
import FooterVNode from '@/components/foot/footer.vue';

export default {
  name: 'CommoditySPUToSKU',

  components: {
    ViewHeader,
    BannerHead,
    CommonTitle,
    ColumnTwo,
    FooterVNode,
  },

  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.commodity-spu-to-sku {
  .gap {
    height: 80px;
  }

  .banner-wrap {
    .show-content {
      position: relative;
      width: 1280px;
      height: 640px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('~@/assets/img/commodity-spu-to-sku/banner-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .header-custom {
    width: 100%;
    margin: auto;
    height: 100%;
    position: absolute;
    display: flex;
    left: 50%;
    transform: translate(-50%);
    align-items: flex-end;
    // padding-bottom: 2.8%;
    box-sizing: border-box;
    justify-content: center;

    img {
      position: absolute;
    }

    .banner1 {
      width: 23.12%;
      left: 1.87%;
      bottom: 0.93%;
    }
    .banner2 {
      width: 23.12%;
      left: 25.93%;
      bottom: 0.93%;
    }
    .banner3 {
      width: 49.06%;
      right: 0;
      bottom: 0.93%;
    }
  }

  /* 核心功能 */
  .core-function {
    position: relative;
    padding: 80px 0 80px;
    width: 1280px;
    margin: auto;

    .common-title {
      margin-bottom: 40px;
    }

    .core-main {
      display: flex;
      gap: 24px;
      background-color: #f8f5ff;
      border-radius: 16px;
      padding: 48px 24px;

      .image-container {
        width: 66.88%;

        img {
          width: 100%;
        }
      }

      .text-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 48px 24px 0;

        .text-item {
          .subtitle {
            margin-bottom: 8px;
            color: #000108;
            font-size: 24px;
            line-height: 34px;
          }

          .text {
            color: #494b5a;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  /* 应用场景 */
  .application-scene {
    background-color: #f3f5f7;

    .application-scene-wrap {
      position: relative;
      padding: 80px 0;
      width: 1280px;
      margin: auto;
    }

    .common-title {
      margin-bottom: 40px;
    }

    .application-scene-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      & > div {
        display: flex;
        border-radius: 16px;
        height: 500px;
      }

      .image-content {
        flex: 1;
        width: 50%;

        img {
          width: 100%;
        }
      }

      .intro-content {
        width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; /* 水平方向左对齐 */

        .text-container {
          width: 63.9%;
          margin: auto;
          text-align: left;
        }

        .subtitle {
          font-weight: 600;
          font-size: 24px;
          line-height: 34px;
          color: #000108;
          margin-bottom: 8px;
        }

        .text {
          line-height: 22px;
          font-size: 16px;
          color: #494b5a;
        }
      }

      .scene_1 {
        background-color: #fff;

        .intro-content {
          padding-left: 24px;
          .text-container {
            margin: unset;
          }
        }
      }

      .scene_2 {
        background: #6c38e0;

        .intro-content {
          padding-left: 48px;
        }

        .text-container {
          width: 86.74%;
          margin: unset;

          .subtitle,
          .text {
            color: #fff;
          }
        }

        .image-content {
          position: relative;
          img {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
  }

  @media screen and (min-width: 991px) and (max-width: 1280px) {
    .banner-wrap {
      .show-content {
        width: 992px;
        height: 495.1px;
      }
    }

    .core-function {
      width: 992px;
    }

    .application-scene .application-scene-wrap {
      width: 992px;
    }

    .application-scene .application-scene-main {
      & > div {
        height: 372px !important;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    /deep/.banner-header {
      background-color: #fff;
    }

    .header-custom {
      width: 38.4rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
